import { all, takeLatest, call, put } from 'redux-saga/effects';
import * as types from 'constants/actions';
import * as fields from 'constants/fields';
import 'firebase/firestore';
import Firestore from 'firebase-service/Firestore';
import { success, error } from 'helpers/reactToaster';
import Cookies from 'js-cookie';
import * as actions from './actions';

const firestore = new Firestore();

function* getUsers() {
  try {
    const result = yield call(
      firestore.getRSF().firestore.getCollection,
      firestore.get().collection('users'),
    );
    yield put(
      actions.setUsers(firestore.sanitizeSnapshot(result, fields.USER)),
    );
  } catch (error) {
    console.log('error getting all users');
  }
}

function* getCurrentUser() {
  try {
    const userEmail = Cookies.get('user_email');
    if (!userEmail) return;
    const result = yield call(
      firestore.getRSF().firestore.getCollection,
      firestore.get().collection('users').where('email', '==', userEmail),
    );
    const user = firestore.sanitizeSnapshot(result, fields.USER);
    const currentUser = user[0];
    if (currentUser?.dob) {
      currentUser.dob = currentUser.dob.toDate();
    }
    yield put(actions.setCurrentUser(currentUser));
  } catch (error) {
    console.log('error getting current user');
  }
}

function* updateUser({ id, user, toUpdateEmail, toUpdatePassword }) {
  let errEmail = false;
  try {
    if (toUpdateEmail) {
      try {
        yield call(
          firestore.getRSF().auth.signInWithEmailAndPassword,
          user.oldemail,
          user.oldpassword,
        );
        yield call(firestore.getRSF().auth.updateEmail, user.email);
        success('Email successfully updated');
      } catch (e) {
        if (e.code === 'auth/argument-error') {
          error('Please input password to Old Password before updating email');
        } else if (e.code === 'auth/wrong-password') {
          error('Password is incorrect');
        }
        errEmail = true;
      }
    }

    if (toUpdatePassword) {
      try {
        console.log(user);
        yield call(
          firestore.getRSF().auth.signInWithEmailAndPassword,
          errEmail ? user.oldemail : user.email,
          user.oldpassword,
        );
        yield call(firestore.getRSF().auth.updatePassword, user.newpassword);
        success('Password successfully updated');
      } catch (e) {
        if (e.code === 'auth/user-not-found') {
          error('Password is incorrect');
        }
      }
    }
  } finally {
    if (errEmail) {
      user.email = user.oldemail;
    }

    delete user.oldemail;
    delete user.oldpassword;
    delete user.newpassword;

    for (const field in user) {
      if (!field) {
        delete user[field];
      }
    }

    try {
      yield call(
        firestore.getRSF().firestore.setDocument,
        `users/${id}`,
        user,
        { merge: true },
      );
      success('Profile updated successfully');
      yield put(actions.getCurrentUser());
    } catch (e) {
      console.log('finally error', e);
      error(e.message);
    }
  }
}

function* UsersSaga() {
  yield all([
    takeLatest(types.GET_USERS, getUsers),
    takeLatest(types.UPDATE_USER, updateUser),
    takeLatest(types.GET_CURRENT_USER, getCurrentUser),
  ]);
}
export default UsersSaga;
