import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import SignupForm from 'components/forms/SignupForm';
import {
  createUser,
  loginUserWithFacebook,
  loginUserWithGoogle,
} from '../services/auth/actions';
import { checkIsLoggedIn } from '../helpers/authentication';
import GoogleLogo from 'icons/GoogleLogo';
import FacebookLogo from 'icons/FacebookLogo';

export class Signup extends Component {
  state = {
    successfulLogin: null,
    verifiedEmail: null,
    isLoggingIn: null,
  };

  static getDerivedStateFromProps(
    { successfulLogin, isLoggingIn, verifiedEmail },
    prevState,
  ) {
    return {
      ...prevState,
      successfulLogin,
      isLoggingIn,
      verifiedEmail,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    if (checkIsLoggedIn()) {
      history.push('/');
    }
  }

  componentDidUpdate() {
    const { successfulLogin, verifiedEmail } = this.state;
    const { history } = this.props;

    if (verifiedEmail !== null) {
      if (!verifiedEmail) {
        history.push('/message/emailNotVerified');
        this.setState({ verifiedEmail: null });
      }
    }

    if (successfulLogin !== null) {
      if (successfulLogin) {
        this.setState({ successfulLogin: null });
        history.push('/');
      }
    }
  }

  createNewUser = (values) => {
    const { createUser } = this.props;
    createUser(values.email, values.password, values);
  };

  loginWithGoogle = () => {
    const { loginUserWithGoogle } = this.props;
    loginUserWithGoogle();
  };

  loginWithFacebook = () => {
    const { loginUserWithFacebook } = this.props;
    loginUserWithFacebook();
  };

  render() {
    const { isLoggingIn } = this.state;
    return (
      <React.Fragment>
        <SignupForm onSubmit={this.createNewUser} isLoggingIn={isLoggingIn} />
        <div className="text-center mt-5">Or</div>
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 offset-lg-4 d-flex flex-column"
              style={{ gap: '2rem' }}>
              <button
                className="bg-transparent w-100 py-3 border-0"
                style={{
                  boxShadow:
                    '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12)',
                }}
                type="button"
                onClick={this.loginWithGoogle}>
                <GoogleLogo className="mr-4" />
                Signup with Google
              </button>

              <button
                className="bg-transparent w-100 py-3 border-0"
                style={{
                  boxShadow:
                    '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12)',
                }}
                type="button"
                onClick={this.loginWithFacebook}>
                <FacebookLogo className="mr-4" />
                Login with Facebook
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(
    ({ authentication }) => ({
      successfulLogin: authentication.successfulLogin,
      isLoggingIn: authentication.isLoggingIn,
      verifiedEmail: authentication.verifiedEmail,
    }),
    {
      createUser,
      loginUserWithGoogle,
      loginUserWithFacebook,
    },
  )(Signup),
);
